import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Modal from '../../components/Modal'
import {
  Flex,
  Box,
  Button,
  Title3,
  Large,
  colors,
} from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { SignUpFormCard } from '../../components/SignUp'
import { VideoFrame } from '../../components/VideoFrame'
import { Quote } from '../../components/Quote'
import Default from '../../components/Layouts/Default';

import CustomersGrid from '../images/customersGrid.svg'

const AboutPage = () => (
      <Default>
      <Helmet title="Truly | Request a Demo">
        <link href="https://fonts.googleapis.com/css?family=Josefin+Sans:400,700&display=swap" rel="stylesheet" />
      </Helmet>
      <WrapperBackground
      color={colors.trulyDark}
      background="chad"
      gradientType="darkLessTransparent"
      >
        <Section py='84px'>
          <Flex flexDirection={['column', 'row', 'row']}>
            <Box center width={[1, 1/2, 1/2]}>
              <VideoFrame background="explainer" caption="Watch Video Overview" />
            </Box>
            <Box width={[1, 1/2, 2/3]} m={[0, "0rem 0rem 0rem 2rem"]} p={["2rem", "0rem 2rem"]}>
            <AlignLeft>
              <Title3 left as="p" color={colors.white} my="2rem">
              Companies that switch to Truly see a 3X increase in rep productivity. 
              </Title3>
              <Large left as="h1" color={colors.white} mb="1rem">
                <b>Truly Gives Teams:</b>
              </Large>
              <Large left as="h1" color={colors.white} mb="0.5rem">
              - Inbound & Outbound Calling
              </Large>
              <Large left as="h1" color={colors.white} mb="0.5rem">
              - Connectivity on Any Device
              </Large>
              <Large left as="h1" color={colors.white} mb="0.5rem">
              - Next Generation Voice Intelligence
              </Large>
              <Large left as="h1" color={colors.white} mb="0.5rem">
              - Intelligent Routing and IVR
              </Large>
              <Large left as="h1" color={colors.white} mb="0.5rem">
              - Reporting and Analytics
              </Large>
              <Large left as="h1" color={colors.white} mb="0.5rem">
              - 30+ Integrations
              </Large>
              <Large left as="h1" color={colors.white} mb="2rem">
              - APIs & SDKs
              </Large>
                <Modal
                  contentStyle={{
                    width: '90%',
                    height: '100%',
                    marginTop: '64pt',
                    overflow: 'scroll'
                  }}
                  button={({ onClick }) => (
                    <Button onClick={onClick} left size="extraLarge" round value="View Demo" />
                  )}
                  modalContent={() => <SignUpFormCard/>}
                />
              </AlignLeft>
            </Box>
          </Flex>
        </Section>
    </WrapperBackground>

    <WrapperSolid
    color={colors.trulyBlue}>
    <FullWidth>
          <Section flexDirection="column">
              <Title3 as="h2" color={colors.white} bold mb="1.5rem" center>
              TALK TO ONE OF<br />OUR VOICE EXPERTS
              </Title3>
              <FullWidth>
              <SignUpFormCard/>
              </FullWidth>
          </Section>
    </FullWidth>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection="column" my={['1rem', '2rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          THE MOST PRODUCTIVE TEAMS MAKE CALLS WITH TRULY
          </Title3>
        </Box>
        <Box width="1" pb="2rem">
          <WidthImg src={CustomersGrid} alt="Customers"/>
        </Box>
      </Section>
    </WrapperSolid>

    <Quote
      quoteAttribution="Timothy,  Jolt"
      quoteText='"The platform is incredibly easy to use and set up. Call quality is exceptional and it has some nice extra features. Very Happy."'
      color={colors.trulyBlue}
      customerPhoto="timothy"
    > 
    </Quote>

    <WrapperBackground
      color={colors.trulyDark}
      background="sf"
      gradientType="light"
    >
      <Section flexDirection="column">
        <Box width={[1, 3/5]} mb="16pt">
          <Large center color={colors.trulyDark}>Would you like to learn more about what Truly can do for your business?</Large>
        </Box> 
        <Modal
          contentStyle={{
            width: '90%',
            height: '100%',
            marginTop: '64pt',
            overflow: 'scroll'
          }}
          button={({ onClick }) => (
            <Button onClick={onClick} color={colors.alertOrange} left size="extraLarge" round value="Talk to Sales" />
          )}
          modalContent={() => <SignUpFormCard/>}
        />
      </Section>
    </WrapperBackground>
    </Default>
)

const FullWidth = styled.div`
  width: 100%;
`

const WidthImg = styled.img`
  width: 100%;
`
const AlignLeft = styled.div`
  text-align: left;
`

export default AboutPage
